<template>
  <div>
    <div v-if="!loading && !pendings.length" class="alert alert-success">
      <i class="fa fa-thumbs-up"></i>
      {{ $t('nochangelogs') }}
    </div>
    <div v-if="loading_all" class="text-center text-warning">
      <i class="fa fa-cog fa-spin fa-fw fa-huge"></i>
    </div>
    <table v-if="pendings.length" class="table">
      <thead>
        <tr>
          <th>
            <i class="fa fa-warning text-danger">
            </i>
            {{$t('mat_change_logs')}}
          </th>
          <th class="text-right">
            <b class="pill">{{ totalMatChangeLogs }}</b>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="changelog in matChangeLog">
          <td>
            <a class="changelog" v-on:click="goLink(changelog.url)">
              <i :class="changelog.icon" class="text-danger"></i>
              {{ $t(changelog.title) }}
            </a>
          </td>
          <td class="text-right">
            <b v-if="Number.isInteger(changelog.data)">{{ changelog.data }}</b>
            <b v-else class="fa fa-cog fa-spin fa-fw text-warning"></b>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>
            <i class="fa fa-warning text-danger">
            </i>
            {{$t('support_change_logs')}}
          </th>
          <th class="text-right">
            <b class="pill">{{ totalSupportChangeLogs }}</b>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="changelog in supportChangeLog">
          <td>
            <a class="changelog" v-on:click="goLink(changelog.url)">
              <i :class="changelog.icon" class="text-danger"></i>
              {{ $t(changelog.title) }}
            </a>
          </td>
          <td class="text-right">
            <b v-if="Number.isInteger(changelog.data)">{{ changelog.data }}</b>
            <b v-else class="fa fa-cog fa-spin fa-fw text-warning"></b>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>
            <i class="fa fa-warning text-danger">
            </i>
            {{$t('changelogpendingwithdrawals')}}
          </th>
          <th class="text-right">
            <b class="pill">{{ totalWithDrawalsPending }}</b>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="changelog in withdrawalChangeLog">
          <td>
            <a class="changelog" v-on:click="goLink(changelog.url)">
              <i :class="changelog.icon" class="text-danger"></i>
              {{ $t(changelog.title) }}
            </a>
          </td>
          <td class="text-right">
            <b v-if="Number.isInteger(changelog.data)">{{ changelog.data }}</b>
            <b v-else class="fa fa-cog fa-spin fa-fw text-warning"></b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import initProgress from "../../../../../UIComponents/Progress";

export default {
  name: "changeloglist",
  components: {
    initProgress,
  },
  props: {
  },
  data() {
    return {
      loading: true,
      loading_all: true,
      processed: 0,
      pendingProcess: 0,
      totalMatChangeLogs: 0,
      totalSupportChangeLogs: 0,
      totalWithDrawalsPending: 0,
      pendings: [],
      changeLogsRef: [],

      matChangeLog: [],
      supportChangeLog: [],
      withdrawalChangeLog: [],

      matChangeLogsRef: [
        'count_change_log_deletes',
        'count_change_log_disconnects',
        'count_change_log_params_change',
        'count_change_log_new_platforms',
        'count_change_log_traded_assets',
        'count_change_log_sync_trades'
      ],
      supportChangeLogsRef: [
        'count_change_log_revalidations',
        'count_change_log_validations'
      ],
      withDrawalsPendingRef: [
        'count_change_log_withdrawals'
      ],
    };
  },
  methods: {
    goLink(url) {
      this.$router.push(url);
    },
    append(changelogs) {
        this.loading = true;
        this.pendings = [];
        this.pendingProcess = changelogs.length;
        this.changeLogsRef = JSON.parse(JSON.stringify(changelogs));

        this.matChangeLog = changelogs.filter(it => this.matChangeLogsRef.includes(it.data));
        this.supportChangeLog = changelogs.filter(it => this.supportChangeLogsRef.includes(it.data));
        this.withdrawalChangeLog = changelogs.filter(it => this.withDrawalsPendingRef.includes(it.data));

        this.$getWidgetData(changelogs[0].data)
          .then(response => this.responseHandler(changelogs, response, 0, changelogs.length), response => this.responseHandler(changelogs, response, 0, changelogs.length))
    },
    responseHandler(changelogs, response, idx, length) {
      if (response.success) {

        changelogs[idx].data = response.data;
        this.pendings.push(changelogs[idx]);

        if (this.matChangeLogsRef.map(it => it).some(item => this.changeLogsRef[idx].data === item)) {
            this.totalMatChangeLogs += changelogs[idx].data;
            let index = this.matChangeLog.findIndex(it => it.url === changelogs[idx].url)
            this.matChangeLog[index] = changelogs[idx];
        }

        if (this.supportChangeLogsRef.map(it => it).some(item => this.changeLogsRef[idx].data === item)) {
            this.totalSupportChangeLogs += changelogs[idx].data;
            let index = this.supportChangeLog.findIndex(it => it.url === changelogs[idx].url)
            this.supportChangeLog[index] = changelogs[idx];
        }

        if (this.withDrawalsPendingRef.map(it => it).some(item => this.changeLogsRef[idx].data === item)) {
            this.totalWithDrawalsPending += changelogs[idx].data;
            let index = this.withdrawalChangeLog.findIndex(it => it.url === changelogs[idx].url)
            this.withdrawalChangeLog[index] = changelogs[idx];
        }
      }

      this.processed++;
      this.loading = !(this.processed === this.pendingProcess);
      idx++;

      if (idx < length) {
          this.$getWidgetData(changelogs[idx].data)
              .then(response => this.responseHandler(changelogs, response, idx, changelogs.length), response => this.responseHandler(changelogs, response, idx, changelogs.length))
      }

      this.loading_all = !(this.processed >= 2)
    },
  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
  .percentage {
    b {
      font-size: 60px;
      opacity: 0.7;
      position: absolute;
      z-index: 1000;
    }
  }
  a {
    text-decoration: none;
    color: black;
    i {
      margin-right: 5px;
    }
    span {
      font-weight: bold;
    }
  }
  a:hover {
    font-weight: bolder;
    cursor: pointer;
  }
  b.pill {
    font-weight: bolder;
    color: red;
  }
</style>
