<script>
import Vue from 'vue';
import {Tooltip} from 'element-ui';

export default {
  name: 'schedulescript',
  components: {
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      broker_schedule: [],
      broker_success: true,
    }
  },
  methods: {
    getFormattedDate(date){
      return this.$moment.utc(date).format("DD/MM/YYYY hh:mm")
    }
  },
  mounted() {
    let broker_success = resp =>  {
      if(resp.success) {
        this.broker_schedule = resp.data
      } else {
        broker_fail(resp)
      }
    }
    let broker_fail = resp => {
      this.broker_success = false
    }

    this.$getScheduleScriptBroker().then(broker_success, broker_fail)
  }

}
</script>

<template>
  <table class="table">
    <thead>
      <tr>
        <th> {{ $t('script_name' )}}</th>
        <th class="text-center"> {{ $t('last_execution' )}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in broker_schedule" v-if="broker_success">
        <td >{{item.name}}</td>
        <td class="text-center"> {{ getFormattedDate(item.date_reference) }}</td>
      </tr>
      <tr v-if="!broker_success">
        <td>
          <span > {{ $t('somethingwentwrong') }}</span>
        </td>
      </tr>
    </tbody>
    <tfoot>
    </tfoot>
  </table>

</template>

<style scoped>
tbody {
    display: block;
    height: 50vh;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
</style>
