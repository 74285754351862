<template>
  <div>
    <div v-if="loading" class="text-center text-info">
      <i class="fa fa-cog fa-spin fa-fw fa-huge"></i>
    </div>
    <div v-if="!loading && !(vps.length + mats.length)" class="alert alert-success">
      <i class="fa fa-thumbs-up"></i>
      {{ $t('novmsoffline') }}
    </div>
    <div v-if="!loading &&  mats.length">
      <h5>
        <i class="fa fa-desktop"></i>
        {{ $t('mats') }}: {{ mats.length }}
      </h5>
      <div v-for="disc in mats" class="offline mat">
        <i class="fa fa-plug"></i>
        {{ disc }}
      </div>
    </div>
    <div v-if="!loading && vps.length">
      <hr>
      <h5>
        <i class="fa fa-laptop"></i>
        {{ $t('countvps') }}: {{ vps.length }}
      </h5>
      <div v-for="disc in vps" class="offline vps">
        <i class="fa fa-plug"></i>
        {{ disc }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "monitoringwidget",
  props: {
  },
  data() {
    return {
      loading: true,
      mats: [],
      vps: [],
    };
  },
  methods: {
    fail(response) {
      console.log('fail', response)
    },
    responseHandler(response) {
      if (!response.success) {
        this.fail(response);
      }
      for (let vm of response.data) {
        if (vm.includes('MAT-')) {
          this.mats.push(vm)
        } else {
          this.vps.push(vm)
        }
      }
      this.loading = false;
    }
  },
  mounted() {
    this.$getWidgetData('list_offline_monitoring')
      .then(this.responseHandler, this.fail)
  }
}
</script>

<style scoped lang="scss">
  .offline {
    display: inline-block;
    border-radius: 5px;
    margin: 2px;
    padding: 5px 10px;
  }
  .offline.mat {
    border-color: rgba(255,0,0,0.1);
    background: rgba(255,0,0,0.5);
    color: white;
  }
  .offline.vps {
    border-color: rgba(0,0,255,0.1);
    background: rgba(0,0,255,0.5);
    color: white;
  }
</style>
