<template>
    <table class="table">
      <thead>
       <tr>
         <th>
           Support Tickets
         </th>
       </tr>
      </thead>
      <tbody>
        <tr v-for="item in issues" v-if="success">
          <td class="cell">
            <span v-on:click="copyit(item.issue)" class="clickable">
              {{ item.issue }}
            </span>
            <input id="clipit" type="text" v-model="clipboard" style="display: none">
          </td>
          <td class="text-right">
            <b v-if="item.status==='To do'" style="color: blue">{{ item.status }}</b>
            <b v-if="item.status==='Doing'" style="color: green">{{ item.status }}</b>
            <b v-if="item.status==='Test'" style="color: red">{{ item.status }}</b>
            <b v-if="item.status==='Ready for Deploy'" style="color: rebeccapurple">{{ item.status }}</b>
          </td>
        </tr>
        <tr v-if="!success">
          <td>
            <span > {{ $t('somethingwentwrong') }}</span>
          </td>
        </tr>
      </tbody>
    </table>
</template>
<script>

import axios from "axios";

export default {
  name: "redminewidget",
  data() {
    return {
      issues: {},
      success: true,
      clipboard: '',
    }
  },
  methods: {
    getFormattedDate(date){
      return this.$moment.utc(date).format("DD/MM/YYYY")
    },
    copyit(value) {
      let cb = document.getElementById("clipit");
      cb.style.display = 'block';
      cb.value = value;
      cb.select();
      document.execCommand('copy');
      cb.style.display = 'none';
      this.$toast.success(`${value} Copied!`)
    },
    async getIssueList(){
      return await axios.get('https://integration.hokocloud.com/api/redmine/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer Auha88daHADuahwDQ0ZD877siDii8dabASJ7DSY6GSD4S65DADg9s'
        }
      })
    }
  },
  mounted() {
    const success = resp => {
      if(typeof resp.data == 'object') {
        this.issues = resp.data
      } else {
        fail(resp)
      }
    }

    const fail = resp => {
      this.success = false
      console.log(resp)
    }

    this.getIssueList().then(success, fail)
  }

}
</script>
<style scoped>
.cell {
  word-wrap: break-word;
  height: 20px;
  width: 100px;
  white-space: nowrap;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    table-layout: auto;
  }


  th, td {
    text-align: justify;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 768px) {
  .cell {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .cell {
    font-size: 10px;
  }
}
</style>
