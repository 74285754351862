<template>
    <table class="table">
      <thead>
        <tr>
          <th>{{  $t('nametxt') }}</th>
          <th class="text-center">{{ $t('date') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in schedule" v-if="success">
          <td>
            {{item.name}}
          </td>
          <td class="text-center">
            {{ getFormattedDate(item.date) }}
          </td>
        </tr>
        <tr v-if="!success">
          <td>
            <span > {{ $t('somethingwentwrong') }}</span>
          </td>
        </tr>
      </tbody>
    </table>
</template>
<script>

export default {
  name: "schedulesupport",
  data() {
    return {
      schedule: {},
      success: true,
    }
  },
  methods: {
    getFormattedDate(date){
      return this.$moment.utc(date).format("DD/MM/YYYY")
    }
  },
  mounted() {
    const success = resp => {
      if(resp.success) {
        this.schedule = resp.data
      } else {
        fail(resp)
      }
    }

    const fail = resp => {
      this.success = false
      console.log(resp)
    }

    this.$getScheduleSupport().then(success, fail)
  }

}
</script>
<style scoped>
.table tr:first-child td {
    background-color: #f7f7f7;
    font-weight: bold;
}
</style>
