<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>
            <i class="nc-icon nc-sound-wave"></i>
            {{ $t('livenumbers') }}
          </h5>
          <hr>
        </div>
        <div class="card-body">
          <div class="col-md-12">
            <div v-if="!counters.length" class="text-center text-info">
              <i class="fa fa-cog fa-spin fa-fw fa-huge"></i>
            </div>
            <div class="row">
              <counter
                class="col-sm-6 col-md-4 col-lg-4 col-xl-2"
                v-for="(counter, index) in counters"
                :key="index"
                :index="index"
                :title="counter.title"
                :url="counter.data"
                :link="counter.link"
                :icon="counter.icon"></counter>
            </div>
          </div>
        </div>
        <div class="card-footer">
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="card" style="min-height: 150px">
        <div class="card-header">
          <h5>
            <i class="fa fa-address-book-o"></i>
            Support Schedule
          </h5>
          <hr>
        </div>
        <div class="card-body">
        <div class="col-md-12">
          <schedulesupport></schedulesupport>
        </div>
      </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h5>
            <i class="fa fa-warning"></i>
            {{ $t('offlinevms') }}
          </h5>
          <hr>
        </div>
        <div class="card-body">
          <div class="col-md-12">
            <monitoringwidget></monitoringwidget>
          </div>
        </div>
        <div class="card-footer">
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4">
      <div class="card">
        <div class="card-header">
          <h5>
            <i class="fa fa-book"></i>
            {{ $t('changelogs') }}
          </h5>
          <hr>
        </div>
        <div class="card-body">
          <div class="col-md-12">
            <changeloglist ref="changelogs"></changeloglist>
          </div>
        </div>
        <div class="card-footer">
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-5">
      <div class="card">
        <div class="card-header">
          <h5>
            <i class="fa fa-book"></i>
            {{ $t('scheduler_script')}}
          </h5>
          <hr>
        </div>
        <div class="card-body">
          <schedulescript></schedulescript>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="col-sm-12 col-md-9">
      <div class="card" style="min-height: 150px">
        <div class="card-header">
          <h5>
            <i class="fa fa-list-ol"></i>
            Redmine
          </h5>
          <hr>
        </div>
        <div class="card-body">
        <div class="col-md-12">
          <redminewidget></redminewidget>
        </div>
      </div>
      </div>
    </div>
<!--    <div class="col-sm-12 col-md-6">-->
<!--      <div class="card">-->
<!--        <div class="card-header">-->
<!--          <h5>-->
<!--            <i class="fa fa-exclamation-circle"></i>-->
<!--            {{ $t('faultysvs') }}-->
<!--          </h5>-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--          <div class="col-md-12">-->
<!--            <serverswidget></serverswidget>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="card-footer">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import counter from "./widgets/counter";
import changeloglist from "./widgets/changeloglist";
import monitoringwidget from "./widgets/monitoringwidget";
import serverswidget from "./widgets/serverswidget";
import Schedulesupport from "@/components/Dashboard/Views/Dashboard/overview/widgets/schedulesupport.vue";
import redminewidget from "@/components/Dashboard/Views/Dashboard/overview/widgets/redminewidget.vue";
import Schedulescript from "@/components/Dashboard/Views/Dashboard/overview/widgets/scheduleScript.vue";

export default {
  name: "Overview",
  components: {
    Schedulescript,
    Schedulesupport,
    counter,
    changeloglist,
    monitoringwidget,
    serverswidget,
    redminewidget
  },
  props: {
  },
  data() {
    return {
      counters: [],
      changelogs: [],
    };
  },
  methods: {
    async loadSkeleton(response) {
      if (!response.success) {
        this.fail(response);
        return;
      }
      for (let widget of response.data.widgets) {
        if (widget.type == 'counter') {
          this.counters.push(widget);
        }
        if (widget.type == 'changelogs') {
          this.$refs.changelogs.append(widget.data);
        }
      }
    },
    fail(response) {
    }
  },
  mounted() {
    this.$getWidgets()
      .then(this.loadSkeleton, this.fail)
  }
}
</script>

<style scoped>
</style>
