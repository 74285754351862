<template>
  <div>
    <div :class="(link ? 'has-link' : '') + ' color-' + index" class="panel text-center" v-on:click="goLink">
      <div class="very-large" style="display: block">
        <i :class="'icon ' + icon"></i>
        <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i>
        <span v-if="!loading && success">{{value}}</span>
        <i v-if="!loading && !success"
           class="fa fa-warning">
        </i>
      </div>
      <div style="display: block" class="link">
        <hr>
        <i class="link fa fa-arrow-right"></i>
        <b>
          {{ $t(title) }}
        </b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  props: {
    index: {
      type: Number,
      description: 'Counter index',
    },
    color: {
      type: String,
      description: 'Counter color',
    },
    icon: {
      type: String,
      description: 'Counter icon',
    },
    title: {
      type: String,
      description: 'Counter title',
    },
    url: {
      type: String,
      description: 'Counter URL data',
      default: ''
    },
    link: {
      type: String,
      description: 'Counter link data',
    },
  },
  data() {
    return {
      loading: true,
      success: true,
      value: null,
    };
  },
  methods: {
    goLink() {
      if (this.link) {
        this.$router.push(this.link);
      }
    },
    loadData(response) {
      this.success = response.success;
      if (!response.success) {
        this.fail(response);
        return;
      }
      this.value = response.data;
      this.loading = false;
    },
    fail(response) {
      this.loading = false;
    }
  },
  mounted() {
    this.$getWidgetData(this.url)
      .then(this.loadData, this.fail)
  }
}
</script>

<style scoped lang="scss">
i.icon {
  font-size: 70px;
  opacity: 0.3;
  display: flex;
  position: absolute;
}
.has-link {
  cursor: pointer;
}
i.link {
  display: none;
}
.has-link:hover {
  .link {
    font-weight: bold;
    i {
      opacity: 0.5;
      display: inline;
    }
  }
}
.panel {
  border: solid 1px;
  margin: 5px;
  padding: 15px;
  border-radius: 5px;
}
.color-0 {
  border-color: rgba(255,0,0,0.1);
  background: rgba(255,0,0,0.5);
  color: white;
}
.color-1 {
  border-color: rgba(0,0,255,0.1);
  background: rgba(0,0,255,0.5);
  color: white;
}
.color-2 {
  border-color: rgba(0,150,0,0.1);
  background: rgba(0,150,0,0.5);
  color: white;
}
.color-3 {
  border-color: rgba(255, 217, 78, 0.3);
  background: rgba(255, 217, 78, 0.7);
  color: white;
}
.color-4 {
  border-color: rgba(126, 187, 255, 0.3);
  background: rgba(126, 187, 255, 0.7);
  color: white;
}
.color-5 {
  border-color: rgba(255,0,255,0.1);
  background: rgba(255,0,255,0.5);
  color: white;
}
.very-large {
  font-size: 50px;
  font-weight: bold;
}
</style>
