<template>
    <div>
      <div v-if="loading" class="text-center text-info">
        <i class="fa fa-cog fa-spin fa-fw fa-huge"></i>
      </div>
      <div v-if="!loading && !(svr.length)" class="alert alert-success">
        <i class="fa fa-thumbs-up"></i>
        {{ $t('nofaultysvs') }}
      </div>
      <table v-if="!loading && svr.length" class="table">
        <tbody>
          <tr v-for="row in svr">
            <td>
              <i class="fa fa-television text-danger"></i>
              {{ $t(row.name) }}
            </td>
            <td class="text-right text-danger">
              <b>{{ row.timepassed }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: "serverswidget",
    props: {
    },
    data() {
      return {
        loading: true,
        svr: [],
      };
    },
    methods: {
      fail(response) {
        console.log('fail', response)
      },
      responseHandler(response) {
        if (!response.success) {
          this.fail(response);
        }
        for (let server of response.data) {
          this.svr.push(server)
        }
        this.loading = false;
      }
    },
    mounted() {
      this.$getWidgetData('list_faulty_server')
        .then(this.responseHandler, this.fail)
    }
  }
  </script>
  
  <style scoped lang="scss">
  .percentage {
    b {
      font-size: 60px;
      opacity: 0.7;
      position: absolute;
      z-index: 1000;
    }
  }
  a {
    text-decoration: none;
    color: black;
    i {
      margin-right: 5px;
    }
    span {
      font-weight: bold;
    }
  }
  a:hover {
    font-weight: bolder;
    cursor: pointer;
  }
  b.pill {
    font-weight: bolder;
    color: red;
  }
</style>
